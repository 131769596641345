import {
  configApiRef,
  createApiFactory,
  createApiRef,
  IdentityApi,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { PluginID } from '../plugin-def';
import { Config } from '@backstage/config';
import { CompoundEntityRef } from '@backstage/catalog-model';
import { GitFile } from '../models';
import {
  DocsComponent,
  DocsFileListResult,
  LoadDocumentRequest,
  LoadDocumentResponse,
  PrResultDto,
  PublishChangesRequest,
  SaveChangesRequest,
  SaveFileRequest,
} from '@internal/backstage-plugin-acc-docs-editor-common';
import { PrAuthor, PrDetails } from '@internal/acc-react-shared';

export class DocsApi {
  private readonly _baseUrl: string;

  constructor(config: Config, private _identity: IdentityApi) {
    this._baseUrl = config.get('backend.baseUrl');
  }

  private async withAuth(options: RequestInit = {}): Promise<RequestInit> {
    const credentials = await this._identity.getCredentials();
    const { headers = {} } = options;
    return {
      ...options,
      headers: {
        ...headers,
        Authorization: `Bearer ${credentials.token}`,
      },
    };
  }

  private async asJson<T>(res: Response): Promise<T> {
    if (res.ok) {
      return res.json();
    }

    return res
      .json()
      .catch(() => {
        return Promise.reject({ message: res.statusText });
      })
      .then(errCause => {
        return Promise.reject(errCause);
      });
  }

  private location(relativePath: string) {
    return `${this._baseUrl}/api/${PluginID}${relativePath}`;
  }

  public async getDocsComponent(
    entity: CompoundEntityRef,
  ): Promise<DocsFileListResult> {
    const { kind, namespace, name } = entity;
    return this.asJson(
      await fetch(
        this.location(`/filelist/${kind}/${namespace}/${name}`),
        await this.withAuth(),
      ),
    );
  }

  public async saveChanges(request: SaveChangesRequest) {
    await fetch(
      this.location(`/save-changes`),
      await this.withAuth({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      }),
    );
  }

  public async saveFile(
    path: string,
    payload: string,
    component: DocsComponent,
  ) {
    await fetch(
      this.location(`/save-document`),
      await this.withAuth({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          path,
          component,
          payload,
        } as SaveFileRequest),
      }),
    );
  }

  public async openDocFile(
    entity: DocsComponent,
    file: GitFile,
  ): Promise<LoadDocumentResponse> {
    return this.asJson(
      await fetch(
        this.location(`/load-document`),
        await this.withAuth({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            path: file.path,
            component: entity,
          } as LoadDocumentRequest),
        }),
      ),
    );
  }

  public async publishChanges(
    details: PrDetails,
    component: DocsComponent,
    branch: string,
    author: PrAuthor,
    token: string | undefined,
  ): Promise<PrResultDto> {
    const request: PublishChangesRequest = {
      description: details.description,
      component: {
        ...component,
        branch,
      },
      title: details.title,
      author: author,
      userToken: token,
    };
    return await this.asJson(
      await fetch(
        this.location(`/publish-changes`),
        await this.withAuth({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(request),
        }),
      ),
    );
  }
}

export const docsApiRef = createApiRef<DocsApi>({
  id: `${PluginID}.docs-api`,
});

export function provideDocsApi() {
  return createApiFactory({
    api: docsApiRef,
    deps: {
      config: configApiRef,
      identity: identityApiRef,
    },
    factory({ config, identity }): DocsApi {
      return new DocsApi(config, identity);
    },
  });
}
