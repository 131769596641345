import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
  st0: {
    fill: theme.palette.text.primary,
  },
  st1: {
    fill: '#E5173F',
  },
  st2: {
    fill: theme.palette.text.primary,
  },
}));

const LogoFull = (props: {
  classes?: {
    svg: string;
    path: string;
  };
}) => {
  const classes = useStyles();

  return (
    <svg
      className={props.classes?.svg ? props.classes.svg : classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 150"
    >
      <path
        className={classes.st1}
        d="M144.9,58.3L95.8,14.8l0,0C85.5,5.7,72.3,0.4,57.6,0.4C25.9,0.4,0,26.3,0,58s25.9,57.6,57.6,57.6
			c14.7,0,27.9-5.6,38.2-14.4l0,0L144.9,58.3z M17.3,58.3c0-22,17.9-40.3,40.3-40.3c22,0,40.3,17.9,40.3,40.3
			c0,22-17.9,40.3-40.3,40.3C35.6,98.3,17.3,80.3,17.3,58.3"
      />

      <path
        className={classes.st2}
        d="M165.5,73.3c0-11.8,9.8-20,38.5-24v-2.8c0-7.1-3.9-10.6-11-10.6c-8.6,0-14.9,3.9-20.8,8.6l-5.5-5.1
			c7.1-6.7,16.1-11.4,27.5-11.4c13.8,0,19.3,7.1,19.3,19.3v25.5c0,7.1,0.4,11,1.6,14.1h-9.8c-0.8-2.4-1.2-4.7-1.2-7.9
			c-6.3,5.9-13.8,8.6-22,8.6C171.8,87.9,165.5,82.8,165.5,73.3 M204,72.1V56c-22,3.5-29.1,8.6-29.1,16.1c0,5.5,3.5,8.6,9.8,8.6
			C191.9,80.4,198.5,77.3,204,72.1"
      />

      <path
        className={classes.st2}
        d="M225.7,58.4c0-20.8,13.4-29.9,26.3-29.9c13,0,18.9,7.5,22.4,16.1l-7.9,3.1c-2.8-7.5-7.5-11.4-14.9-11.4
			c-8.3,0-16.5,5.9-16.5,22c0,14.1,6.7,22,16.5,22c7.5,0,11.8-3.1,15.3-11.4l7.9,3.1c-4.3,9.4-11,16.5-23.6,16.5
			C238.2,88.3,225.7,78,225.7,58.4"
      />

      <path
        className={classes.st2}
        d="M283,58.4c0-20.8,13.4-29.9,26.3-29.9s18.9,7.5,22.4,16.1l-7.9,3.1c-2.8-7.5-7.5-11.4-14.9-11.4
			c-8.3,0-16.5,5.9-16.5,22c0,14.1,6.7,22,16.5,22c7.5,0,11.8-3.1,15.3-11.4l7.9,3.1c-4.3,9.4-11,16.5-23.6,16.5
			C295.6,88.3,283,78,283,58.4"
      />

      <path
        className={classes.st2}
        d="M340.4,58.4c0-20.8,13.8-29.9,25.9-29.9c13.8,0,24,9.4,24,30.3v2h-40.5c0.4,13,7.9,20,17.3,20
			c8.3,0,13-3.5,17.3-9.4l5.5,4.7c-5.5,7.5-12.6,12.2-23.6,12.2C353,88.3,340.4,78.4,340.4,58.4 M381.7,53.3
			c-0.8-10.2-5.5-17.3-14.9-17.3c-7.9,0-14.9,5.5-16.5,17.3H381.7z"
      />

      <path
        className={classes.st2}
        d="M398.6,78.4l5.5-5.5c4.3,4.7,10.2,7.9,18.1,7.9c9,0,13.8-3.1,13.8-9c0-5.9-5.1-7.9-14.9-10.2
			c-12.2-2.8-20.4-6.3-20.4-17.3c0-8.6,7.5-15.7,20-15.7c10.2,0,16.5,3.1,22,8.3l-4.7,6.3c-4.3-3.9-10.2-6.7-16.5-6.7
			c-8.3,0-11.8,3.5-11.8,7.9c0,5.5,4.7,7.5,13.8,9.4c13,3.1,22,6.3,22,18.1c0,11-9.8,16.9-22.8,16.9C412,87.9,403.3,83.9,398.6,78.4
			"
      />

      <path
        className={classes.st2}
        d="M453.2,78.4l5.5-5.5c4.3,4.7,10.2,7.9,18.1,7.9c9,0,13.8-3.1,13.8-9c0-5.9-5.1-7.9-14.9-10.2
			c-12.2-2.8-20.4-6.3-20.4-17.3c0-8.6,7.5-15.7,20-15.7c10.2,0,16.5,3.1,22,8.3l-4.7,6.3c-4.3-3.9-10.2-6.7-16.5-6.7
			c-8.3,0-11.8,3.5-11.8,7.9c0,5.5,4.7,7.5,13.8,9.4c13,3.1,22,6.3,22,18.1c0,11-9.8,16.9-22.8,16.9
			C466.6,87.9,457.9,83.9,453.2,78.4"
      />
    </svg>
  );
};

export default LogoFull;
