// packages/app/src/components/home/HomePage.tsx
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  HomePageTopVisited,
  HomePageRecentlyVisited,
  HeaderWorldClock,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  HomePageToolkit,
  WelcomeTitle,
  FeaturedDocsCard,
} from '@backstage/plugin-home';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Content, Header, InfoCard, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { XkcdComicCard } from 'backstage-plugin-xkcd';
import { Typography, makeStyles } from '@material-ui/core';
import LogoFull from '../Root/LogoFull';

import { tools, clockConfigs, timeFormat } from './shared';

const languages = ['English', 'Australian', 'Irish Gaelic', 'Malay'];

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0, 0, 0),
  },
  svg: {
    width: 'auto',
    height: 120,
  },
  path: {
    fill: theme.palette.text.primary,
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();

  // Use the search bar and starred entities as is
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header
          title={<WelcomeTitle language={languages} />}
          pageTitleOverride="Home"
        >
          <HeaderWorldClock
            clockConfigs={clockConfigs}
            customTimeFormat={timeFormat}
          />
        </Header>
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<LogoFull classes={{ svg, path }} />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <InfoCard title="Welcome to Access Backstage.">
                  <Typography paragraph>
                    As we continually strive to improve our engineering
                    excellence, we are excited to introduce our own internal
                    developer portal, built on the robust foundations of
                    Backstage by Spotify.
                  </Typography>
                </InfoCard>
              </Grid>

              <Grid item xs={12} md={6}>
                <HomePageToolkit tools={tools} />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} md={4}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={4}>
                <HomePageRecentlyVisited />
              </Grid>
              <Grid item xs={12} md={4}>
                <HomePageTopVisited />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <XkcdComicCard />
              </Grid>
              <Grid item xs={12} md={6}>
                <FeaturedDocsCard
                  filter={{
                    'metadata.tags': 'recommended',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
