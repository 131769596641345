import React from 'react';
import { Button } from '@material-ui/core';
import { useRouteRef, useRouteRefParams } from '@backstage/core-plugin-api';
import { rootRouteRef } from '../routes';
import { useNavigate } from 'react-router-dom';

export function EditTechDocs() {
  const { kind, name, namespace } = useRouteRefParams(rootRouteRef);
  const builder = useRouteRef(rootRouteRef);
  const navigate = useNavigate();

  const handleEdit = () => {
    const result = builder({ namespace, kind, name });
    navigate(result);
  };
  return <Button onClick={handleEdit}>Edit Techodcs</Button>;
}
