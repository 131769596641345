import React from 'react';
import { makeStyles } from '@material-ui/core';
import './styles.css'; // Import the CSS file

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  cls1: {
    fill: '#ffb3ba',
    fontFamily: 'Roboto, sans-serif',
  },
  cls2: {
    fill: '#eb6f7b',
  },
  cls3: {
    fill: '#e5173f',
  },
  cls4: {
    fill: '#fff',
  },
});

const AxlLogo = (props: {
  classes?: {
    svg: string;
    path: string;
  };
}) => {
  const classes = useStyles();

  return (
    <svg
      className={props.classes?.svg ? props.classes.svg : classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56.942 53.831"
    >
      <g
        id="Group_2417"
        data-name="Group 2417"
        transform="translate(-69.643 -19.199)"
      >
        <g
          id="Group_2416"
          data-name="Group 2416"
          transform="translate(69.642 19.199)"
        >
          <g id="Group_2336" data-name="Group 2336">
            <path
              id="Path_3280"
              data-name="Path 3280"
              className={classes.cls1}
              d="M-4707.628-1914.273l28.471-16.525-28.471-16.2-28.471,16.2Z"
              transform="translate(4736.1 1968.103)"
            />
            <path
              id="Path_3279"
              data-name="Path 3279"
              className={classes.cls2}
              d="M-4707.628-1914.273l28.471-16.525-28.471-16.2-28.471,16.2Z"
              transform="translate(4736.1 1957.552)"
            />
            <path
              id="Path_3278"
              data-name="Path 3278"
              className={classes.cls3}
              d="M-4707.628-1914.273l28.471-16.525-28.471-16.2-28.471,16.2Z"
              transform="translate(4736.1 1947)"
            />
          </g>
          <path
            id="Subtraction_91"
            data-name="Subtraction 91"
            className={classes.cls4}
            d="M9.076,13.425a12.052,12.052,0,0,1-3.533-.516A10.546,10.546,0,0,1,4,12.3a9.152,9.152,0,0,1-1.343-.8,7.663,7.663,0,0,1-1.108-.973A6.31,6.31,0,0,1,.713,9.411,5.022,5.022,0,0,1,0,6.853,4.637,4.637,0,0,1,.2,5.516,5.488,5.488,0,0,1,.771,4.251a6.9,6.9,0,0,1,.9-1.161A8.569,8.569,0,0,1,2.845,2.065a10.13,10.13,0,0,1,1.412-.854A11.254,11.254,0,0,1,5.858.56,11.76,11.76,0,0,1,9.449,0a13.366,13.366,0,0,1,3.321.4,12.338,12.338,0,0,1,2.825,1.087.624.624,0,0,1,.139.049l7.933,4.294-7.933,5.75-.03.016L15.7,11.6a6.319,6.319,0,0,1-.648.361,14.743,14.743,0,0,1-1.987.8A13.972,13.972,0,0,1,9.076,13.425ZM9.523,1.954A8.073,8.073,0,0,0,5.047,3.2,3.711,3.711,0,0,0,3.192,6.222a3.737,3.737,0,0,0,1.9,3.018,7.984,7.984,0,0,0,4.345,1.25,8.367,8.367,0,0,0,4.431-1.25,3.737,3.737,0,0,0,1.986-3.018A3.711,3.711,0,0,0,14,3.2,8.074,8.074,0,0,0,9.523,1.954Z"
            transform="translate(17.753 8.656)"
          />
        </g>
      </g>
    </svg>
  );
};

export default AxlLogo;
