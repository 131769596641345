import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';

import { rootRouteRef } from './routes';
import { EditTechDocs } from './components/EditTechDocsOption';
import { PluginID } from './plugin-def';
import { provideDocsApi } from './services/docs-api';

export const accDocsEditorPlugin = createPlugin({
  id: PluginID,
  routes: {
    root: rootRouteRef,
  },
  apis: [provideDocsApi()],
});

export const AccDocsEditorPage = accDocsEditorPlugin.provide(
  createRoutableExtension({
    name: 'AccDocsEditorPage',
    component: () =>
      import('./components/Editor/TechDocsEditor').then(m => m.TechDocsEditor),
    mountPoint: rootRouteRef,
  }),
);

export const EditTechDocsSetting = accDocsEditorPlugin.provide(
  createTechDocsAddonExtension({
    name: 'EditTechDocsOption',
    location: TechDocsAddonLocations.Settings,
    component: EditTechDocs,
  }),
);
