import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
  cls1: {
    fill: '#f2f2f2',
  },
  cls4: {
    fill: '#4b112c',
  },
  cls3: {
    fill: '#54b9b3',
  },
  cls2: {
    fill: '#e5173f',
  },
});

const CompassLogo = (props: {
  classes?: {
    svg: string;
    path: string;
  };
}) => {
  const classes = useStyles();

  return (
    <svg
      className={props.classes?.svg ? props.classes.svg : classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 428.8 420.21"
    >
      <circle className={classes.cls1} cx="214.4" cy="210.1" r="196.4" />
      <g>
        <path
          className={classes.cls4}
          d="M197.16,237.98l42.68,80.64c1.56,2.94,5.96,2.22,6.5-1.06l33.29-202.43c.51-3.09-2.94-5.29-5.53-3.53L104.57,227.07c-2.76,1.88-1.55,6.18,1.78,6.35l90.81,4.57Z"
        />
        <path
          className={classes.cls3}
          d="M214.68,13.7C106.38,13.7,18.28,101.81,18.28,210.1s88.1,196.4,196.4,196.4,196.4-88.1,196.4-196.4S322.97,13.7,214.68,13.7Zm127.69,315.21l-18.99-18.99-8.88,8.88,18.99,18.99c-27.06,25.19-62.09,41.92-100.85,45.9l-17.96-62.35-17.96,62.35c-38.76-3.98-73.8-20.71-100.85-45.9l18.99-18.99-8.88-8.88-18.99,18.99c-25.19-27.06-41.92-62.09-45.9-100.85l62.35-17.96-62.35-17.96c3.98-38.76,20.71-73.8,45.9-100.85l18.99,18.99,8.88-8.88-18.99-18.99c27.06-25.19,62.09-41.92,100.85-45.9l17.96,62.35,17.96-62.35c38.76,3.98,73.8,20.71,100.85,45.9l-18.99,18.99,8.88,8.88,18.99-18.99c25.19,27.06,41.92,62.09,45.9,100.85l-62.35,17.96,62.35,17.96c-3.98,38.76-20.71,73.79-45.9,100.85Z"
        />
        <path
          className={classes.cls2}
          d="M197.16,237.98l42.68,80.64c1.56,2.94,5.96,2.22,6.5-1.06l33.29-202.43c.25-1.53-.46-2.84-1.56-3.56l-80.91,126.41Z"
        />
      </g>
    </svg>
  );
};

export default CompassLogo;
